<template>
  <div>
    <div class="d-flex align-items-center">
      <h1 class="h3">
        Atur Supplier
      </h1>
      <div class="ml-auto">
        <a-button type="primary" size="large" @click="showModalAddSupplier = true">
          <a-icon type="plus-square" />
          Tambah Supplier
        </a-button>
      </div>
    </div>
    <div class="mt-4">
      <a-table
        class="table-list"
        :data-source="supplierList"
        :row-key="record => record.id"
        :columns="columns"
        :loading="isLoading"
        :pagination="false"
        :scroll="scroll"
      />
    </div>
    <div class="mt-4 text-right">
      <a-pagination
        v-model="params.page"
        :page-size="params.size"
        :page-size-options="sizeOptions"
        :total="totalRow"
        :show-total="(total, range) => $t('utils.pagination_show_total', { perpage: `${range[0]} - ${range[1]}`, total })"
        :disabled="isLoading"
        show-size-changer
        @change="onPageChange"
        @showSizeChange="onShowSizeChange"
      />
    </div>
    <ModalAddSupplier
      :is-loading="isLoading"
      :visible="showModalAddSupplier"
      :business-id="businessId"
      @onCancel="onCloseModalAddSupplier"
    />
  </div>
</template>

<script>
export default {
  name: 'ListProductDiscount',
}
</script>
<script setup>
import debounce from 'lodash/debounce'
import { computed, getCurrentInstance, onMounted, reactive, ref, toRefs, watch } from 'vue'
import { getListSupplier } from '@/api/purchase-order'
import { columnsSupplier } from '@/data/columns'
import ModalAddSupplier from '@/views/supplier/ModalAddSupplier.vue'

const { $i18n, $route, $router, $store, $moment } = getCurrentInstance().proxy.$root

const INITIAL_SORT = {
  sort: ['createdAt'],
  order: ['DESC'],
}

const INITIAL_PARAMS = {
  search: '',
  start_date: '',
  end_date: '',
  status: 'ALL',
  sort: 'createdAt',
  order: 'DESC',
  page: 1,
  size: 10,
}

// Data
const businessId = ref($route.query.business_id || Object.keys($store.getters['user/businessListObjectByKey'])[0])
const screenHeight = ref(((screen.height - 900) * 0.4) + (screen.height * 0.4))
const isLoading = ref(false)
const columns = ref(columnsSupplier)
const supplierList = ref([])
const showModalAddSupplier = ref(false)
const totalRow = ref(0)
const selectedDiscounts = ref([])
const sizeOptions = ref(['10', '20', '30', '40', '50'])
const sorting = reactive(INITIAL_SORT)
const params = reactive(INITIAL_PARAMS)

// Refs
const refSorting = toRefs(sorting)
const refParams = toRefs(params)

// Computed
const business = computed(() => Object.keys($store.getters['user/businessListObjectByKey'])[0])
const scroll = computed(() => ({ y: screenHeight.value > 360 ? screenHeight.value : 360, x: '1000px' }))
const tHeight = computed(() => {
  return !supplierList.value.length ? '0px'
    : screenHeight.value > 360 ? `${screenHeight.value}px`
    : '360px'
})

const init = () => {
  selectedDiscounts.value = []
  refSorting.value = INITIAL_SORT
  refParams.value = INITIAL_PARAMS
  fetchListSupplier()
}

const fetchListSupplier = debounce(async () => {
  isLoading.value = true
  const selectedBusinessId = businessId.value
  await getListSupplier({
    business_id: selectedBusinessId,
    params: {
      ...params,
      business_id: selectedBusinessId,
    },
  })
  .then(({ data }) => {
    supplierList.value = data?.data || []
    totalRow.value = data?.total_items || 0
  })
  .catch(() => {
    supplierList.value = []
    totalRow.value = 0
  })
  .finally(() => isLoading.value = false)
}, 500)

const onPageChange = (pageNumber) => {
  selectedDiscounts.value = []
  params.page = pageNumber
  fetchListSupplier()
}

const onShowSizeChange = (current, pageSize) => {
  selectedDiscounts.value = []
  params.page = current
  params.size = pageSize
  fetchListSupplier()
}

const onCloseModalAddSupplier = (isSubmitted) => {
  showModalAddSupplier.value = false
  if (isSubmitted) {
    init()
  }
}
// Watch
watch(() => 
  business.value, (newValue) => {
    businessId.value = newValue
    init()
  })
watch(() => 
  $route, (newValue) => {
    if (newValue.name === $route.name) {
      init()
    }
  })

// Mounted
onMounted(init)
</script>

<style lang="scss">
.fs-14 {
  font-size: 14px !important;
}
.fw-bold {
  font-weight: bold;
}
.text-capitalize {
  text-transform: capitalize
}
.sorting-checkbox {
  .ant-checkbox-group-item {
    display: block !important;
    padding: 5px 0;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .ant-checkbox-inner {
    border-color: transparent !important;
  }
  .ant-checkbox-checked {
    &::after {
      border-color: transparent !important;
    }
    .ant-checkbox-inner {
      background-color: transparent !important;

      &::after {
        border-color: #ff0a54 !important;
      }
    }
  }
}
.dropdown-title {
  font-size: 12px;
  font-weight: bold;
  color: #6c757d;
}
.table-list div.ant-table-body {
  min-height: v-bind(tHeight);
}
.ant-btn-info {
  color: #2196f3 !important;
  border-color: #2196f3 !important;
}
.ant-dropdown-menu-item {
  background: #fff !important;
}
.ant-table-row {
  background-color: #fff !important;

  &.ant-table-row-selected > td {
    background-color: #fff !important;
  }
}
.pca-tab-store {
  .ant-tabs-bar {
    background-color: #fff;
    margin-bottom: 0;
    border-bottom-color: transparent;
  }
  .ant-tabs-nav .ant-tabs-tab:hover,
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #ff0a54;
  }
  .ant-tabs-ink-bar {
    background-color: #ff0a54;
  }
}
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
</style>